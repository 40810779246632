<template>
  <div id="glauco-resume-patient-dash">
    <v-glauco-resume :eyeCareBIPatient="eyeCareBIPatient">
      <hr />
      <b-container class="access-key">
        <b-row>
          <b-col cols="6">
            <div class="d-flex flex-column">
              <p class="label-default">Código utilizado</p>
              <span class="access-key-number">{{ accessKey.key }}</span>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="text-right">
              <b-button
                variant="link"
                class="text-success"
                :disabled="!accessKey.revoked_at"
                @click="activateAccessKey"
              >
                Ativar código
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="6">
            <div class="d-flex flex-column">
              <span class="label-default">Data de ativação</span>
              <span class="date-placeholder">
                {{ moment(accessKey.used_at).format('DD/MM/YYYY') }}
              </span>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex flex-column">
              <span class="label-default">Situação</span>
              <span class="status" :class="parseStatus(accessKey.revoked_at)">
                {{ parseStatus(accessKey.revoked_at) }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </v-glauco-resume>
  </div>
</template>

<script>
import GlaucoResume from '@/components/Glauco/Dashboard/Patient/GlaucoResume'

export default {
  name: 'GlaucoResumePatient',
  components: {
    'v-glauco-resume': GlaucoResume
  },
  props: {
    eyeCareBIPatient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accessKey: {}
    }
  },
  methods: {
    async getPatientAccessKey() {
      try {
        const response = await this.api.getPatientAccessKey(
          this.eyeCareBIPatient.id
        )
        this.accessKey = response.data
      } catch (error) {
        this.$toast.error('Erro ao buscar chave de acesso')
      }
    },
    async activateAccessKey() {
      const isLoading = this.$loading.show()
      try {
        await this.api.activateCode(this.eyeCareBIPatient.id)
        this.$toast.success('Código ativado com sucesso')
        this.accessKey.revoked_at = null
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    parseStatus(revoked) {
      if (revoked) return 'Revogado'
      return 'Ativo'
    }
  },
  watch: {
    eyeCareBIPatient: {
      async handler(newValue) {
        if (newValue?.id) {
          await this.getPatientAccessKey()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#glauco-resume-patient-dash {
  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }
  .access-key {
    height: 100%;
    padding: 30px 24px;
    margin: 0 auto;

    .label-default {
      font-size: min(1vw, 14px);
      font-weight: 600;
      font-family: 'Nunito Sans';
      color: var(--dark-blue);
      margin-bottom: 2.5% !important;
    }

    .text-success {
      font-weight: 600;
      font-size: min(1vw, 14px);
    }

    .access-key-number {
      font-size: min(2.8vw, 24px);
      font-weight: 700;
      font-family: 'Red Hat Display';
      color: var(--blue-500);
    }

    .date-placeholder {
      font-size: min(1vw, 14px);
      font-weight: 600;
      font-family: 'Nunito Sans';
      color: var(--type-placeholder);
    }

    .status {
      color: var(--type-active);
      font-size: min(1.5vh, 12px);
      font-weight: 600;
      border: 1px solid transparent;
      padding: 4px 8px;
      width: fit-content !important;
      border-radius: 16px;

      &.Ativo {
        background-color: var(--states-success-soft-green);
      }

      &.Revogado {
        background-color: var(--light-orange-200);
      }
    }
  }
}
</style>
