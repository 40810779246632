<template>
  <div id="glauco-resume-dashboard">
    <div class="main-container">
      <v-glauco-icon class="icon" />
      <p class="main-title">Resumo Glauco</p>
    </div>
    <div class="glauco">
      <p class="title">
        por Glauco
        <span class="logo-glauco">
          <v-glauco-icon />
        </span>
      </p>
      <b-row class="d-flex justify-content-around">
        <b-col cols="auto">
          <div class="d-flex justify-content-center align-items-center">
            <p class="number">{{ frequency }}</p>
          </div>
          <p class="info">Dias sem falhas</p>
        </b-col>
        <b-col cols="auto">
          <div class="d-flex justify-content-center">
            <p class="number">
              {{
                parseInt(
                  calcAderency(moment().year(), moment().add(1, 'M').month()),
                  10
                )
              }}%
            </p>
            <div class="gauge-area">
              <VueSvgGauge
                :start-angle="-90"
                :end-angle="90"
                :value="calcAderency(moment(), moment().add(1, 'M').month())"
                :min="0"
                :max="100"
                :separator-step="0"
                base-color="#D9DFF2"
                gauge-color="#00C773"
              />
            </div>
          </div>
          <p class="info">Sucesso no mês</p>
        </b-col>
      </b-row>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
import GlaucoIcon from '@/components/Glauco/GlaucoIcon'

export default {
  name: 'GlaucoResume',
  props: {
    eyeCareBIPatient: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'v-glauco-icon': GlaucoIcon
  },
  data() {
    return {
      patientEyedrops: [],
      allEyedrops: [],
      frequency: 0
    }
  },
  methods: {
    calcAderency(year, month) {
      const aderencyMonths = Array.from({ length: 13 }, (_, i) => i * 0)

      const distinctDrops = []
      this.patientEyedrops.forEach(eyedropDate => {
        distinctDrops.push(this.moment(eyedropDate.date).format('YYYY-MM-DD'))
      })

      new Set(distinctDrops).forEach(drop => {
        const dateEyedrop = drop
        const eyedropDateMonth = this.moment(dateEyedrop).format('MM')
        if (
          this.moment(year, 'YYYY').format('YYYY') ===
          this.moment(dateEyedrop).format('YYYY')
        ) {
          aderencyMonths[parseInt(eyedropDateMonth)]++
        }
      })

      month = parseInt(month)

      let allDrops = 1
      this.allEyedrops.find(d => {
        if (
          this.moment(d.datetime).format('YYYY-MM') ===
          this.moment(year + '-' + month, 'YYYY-MM').format('YYYY-MM')
        ) {
          allDrops++
        }
      })
      return (aderencyMonths[month] * 100) / allDrops
    },
    async getEyeDrops() {
      try {
        const response = await this.api.getPatientEyeDrops(
          this.eyeCareBIPatient.id
        )

        this.patientEyedrops = []
        response.data.forEach(eyedrop => {
          this.patientEyedrops.push({
            date: eyedrop.updated_at,
            count: eyedrop.additional_data.amountOfDrops
          })
        })

        let compareDate = this.moment().format('YYYY-MM-DD')
        this.patientEyedrops.find(d => {
          while (compareDate === this.moment(d.date).format('YYYY-MM-DD')) {
            compareDate = this.moment(compareDate)
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
            this.frequency++
          }
        })
      } catch (error) {
        console.error(error)
        // this.$toast.error(error.message)
      }
    },
    async getAllEyeDrops() {
      try {
        const response = await this.api.getAllPatientEyeDrops(
          this.eyeCareBIPatient.id
        )
        this.allEyedrops = response.data
      } catch (error) {
        console.error(error)
        // this.$toast.error(error.message)
      }
    }
  },
  watch: {
    eyeCareBIPatient: {
      async handler(value) {
        if (!value?.id) return
        await this.getEyeDrops()
        await this.getAllEyeDrops()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#glauco-resume-dashboard {
  .main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 3%;

    .icon {
      width: 17px;
      height: 17px;
      margin-right: 1%;
    }

    .main-title {
      color: var(--dark-blue);
      font-size: min(1.2vw, 16px);
      font-family: 'Red Hat Display';
      font-weight: 500;
    }
  }

  margin-top: 2%;
  background-color: var(--neutral-000);
  border-radius: 8px;
  padding: 15px;

  .glauco {
    width: 95%;
    margin-top: 4% !important;
    margin: 0 auto;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-bottom: 24px;

    .title {
      position: absolute;
      display: flex;
      background-color: var(--neutral-000);
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 5px;
      font-weight: 600;
      font-size: 10px;

      .logo-glauco {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 17px;
          height: 17px;
          margin-left: 4px;
        }
      }
    }

    .number {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: var(--type-active);
      margin-bottom: 0;
      margin-right: 5px;
    }

    .gauge-area {
      width: 32px;
      height: 16px;
      margin-top: -4px;
    }

    .badge {
      width: 47px;
      height: 20px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 12px;
      color: var(--neutral-000);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      .arrow {
        width: 8px;
        height: 8px;
        stroke: var(--neutral-000);
        margin-right: 4px;
      }

      &.green {
        background-color: var(--states-success);
      }
    }

    .info {
      font-size: 12px;
      color: var(--type-placeholder);
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}
</style>
